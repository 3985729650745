<template>
  <div class="tui tuim ui segments history">
    <h3 class="tui tuim ui header segment" v-if="title">
      <t-icon :icon="[ 'fas', icon ]" class="icon-card" />
      <span v-text="title" />
    </h3>
    <slot />
    <div class="tui tuim ui footer segment" v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TGroupCards',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'clock'
    }
  }
}
</script>

<style lang="scss">
.tui.tuim.ui {
  &.segments.history {
    border: $border;
    border-radius: 3px;
    box-shadow: none;

    .tui.tuim.ui.segment {
      padding-top: 10px;
      padding-bottom: 10px;

      &.header {
        background: #fafafa;
        border-bottom: $border;
        color: #a3a3a3;
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 23px;

        .icon-card {
          margin-right: 10px;
          margin-left: 10px;
          vertical-align: middle;
        }
      }

      &.footer {
        padding: 0;

        .ui.button {
          background-color: #fff;
          border: none;
          color: #a3a3a3;
          padding: 14px;
          text-transform: uppercase;
          font-weight: $font-weight-regular;
        }
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tui tuim ui segments history" },
    [
      _vm.title
        ? _c(
            "h3",
            { staticClass: "tui tuim ui header segment" },
            [
              _c("t-icon", {
                staticClass: "icon-card",
                attrs: { icon: ["fas", _vm.icon] }
              }),
              _c("span", { domProps: { textContent: _vm._s(_vm.title) } })
            ],
            1
          )
        : _vm._e(),
      _vm._t("default"),
      _vm.$slots.footer
        ? _c(
            "div",
            { staticClass: "tui tuim ui footer segment" },
            [_vm._t("footer")],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }